import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import BlogPost from 'pageComponents/BlogPost';

const PostPage = ({ params }: PageProps) => {
  const { postTitle } = params;

  return <BlogPost searchParam={postTitle} />;
};

export function Head() {
  const { t } = useI18next();
  return (
    <title>{t('home.atf.navigation_tabs.insights')} | Safebrok</title>
  );
}

export default PostPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

/*
for the blog route, we are hardcoding the language prefix because the plugin used to generate the language prefix in the url does not support dynamic routes like this one, see: https://github.com/microapps/gatsby-plugin-react-i18next/issues/69
*/
